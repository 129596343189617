import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import SEO from "../components/seo"
import Image from "../components/image"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import "../styles/styles.css"

import about from '../../static/images/cara.svg'

const About = () => {
  const data = useStaticQuery(graphql`
    query Aboutquery {
      markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
        frontmatter {
          about
          thumbnail
          title
        }
      }
    }
  `)

  const toHTML = value =>
    remark().use(recommended).use(remarkHtml).processSync(value).toString()

  function createMarkup(data) {
    const dataHtml = toHTML(data)

    return { __html: dataHtml }
  }

  return (
    <div>
      <SEO
        title="Tentang Kami"
        url="https://nibrasplay.co.id/"
        image="https://nibrasplay.co.id/images/favicon.png"
      />
      <Header />
      <div className="container-policy ">
        <div className="policy">
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          {/* <Image
            name={data.markdownRemark.frontmatter.thumbnail.replace(
              "../src/images/",
              ""
            )}
          /> */}
          <img src={about} className="rounded mx-auto d-block"></img>
        </div>
        <div
          className="policy-content mt-4"
          dangerouslySetInnerHTML={createMarkup(
            data.markdownRemark.frontmatter.about
          )}
        ></div>
      </div>
      <Footer />
    </div>
  )
}

export default About
